import { Auth, Amplify } from "aws-amplify"
import { navigate } from "gatsby"
import { ISiteLink } from "models/ISiteLink"
import React, { useEffect, useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import { breakpoints } from "../../utils"
import { AWS_CONFIG } from "../../../aws-config"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"
import theme from "./theme"
import deleteProfileFromSessionStorage from "../../components/QueryUserProfile/deleteProfileFromSessionStorage"
import { apiFetchUser } from "api/queries"
import { makeUserData } from "components/profilePage/utils"
import getProfileFromSessionStorage from "components/QueryUserProfile/getProfileFromSessionStorage"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import { UserType } from "api/types"
import isEqual from "lodash/isEqual"

const Container = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const Content = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 106px;
  padding: 0 1rem;
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    margin-top: 60px;
  }
`
const Wrapper = styled.div`
  width: 100%;
  max-width: 1180px;

  @media (max-width: ${breakpoints.extraLargeDesktop}px) {
    padding: 0 4rem;
    max-width: 1552px;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 2rem;
  }
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    padding: 0 1rem;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0px;
    width: 400px;
  }
`

interface LayoutProps {
  readonly children: React.ReactNode
  readonly className?: string
  readonly containerStyles?: any
  readonly subPageRightNav?: any
}

interface ChildProps {
  readonly loggedInUser: UserType
  updateUser: Function
}

const links: ISiteLink[] = [
  { uri: "/", text: "Home", menu: true, onlyMobile: false },
  {
    uri: "/dashboard",
    text: "Dashboard",
    menu: true,
    loginRequired: true,
    onlyMobile: true,
  },
  { uri: "/discover", text: "Discover", menu: true },
  { uri: "/manage", text: "Manage", menu: true },
  { uri: "/search", text: "Search", menu: true },
  {
    uri: "/self-assessment",
    text: "Self assessment",
    menu: true,
    loginRequired: true,
    onlyMobile: true,
  },
  {
    uri: "/qpl",
    text: "Questions i want to ask",
    menu: true,
    loginRequired: true,
    onlyMobile: true,
  },

  {
    uri: "/#topics",
    text: "Topics",
    menu: false,
    loginRequired: true,
    onlyMobile: true,
  },

  { uri: "/discuss", text: "Discussion forum", menu: true },

  {
    uri: "/participants",
    text: "Participants",
    adminOnly: true,
    menu: false,
    loginRequired: true,
  },
  {
    uri: "/qpl",
    text: "Discussion forum",
    menu: false,
    isSubpage: true,
  },
  { uri: "/profile", text: "My Profile", menu: false, loginRequired: true },
]

type RoleType = "Admin" | "Patient" | null

const Layout: React.FC<LayoutProps> = ({
  children,
  className,
  containerStyles,
  subPageRightNav,
}) => {
  const [user, setUser] = useState<UserType>()
  const [role, setRole] = useState<RoleType>()

  const logout = () => {
    Auth.signOut()
      .then(() => {
        deleteProfileFromSessionStorage()
        setUser(undefined)
        navigate("/")
      })
      .catch(err => console.log(err))
  }

  let activeUri =
    typeof window !== `undefined` ? window.location.pathname.split("/")[1] : ""
  let homeUrl = ""
  let pageName = "Home",
    activeLink: ISiteLink | undefined
  activeUri ? (activeUri = `/${activeUri}`) : (activeUri = `/${homeUrl}`)
  activeLink = links.find(item => item.uri === activeUri)
  pageName = activeLink?.text as string

  const fetchUserData = async (sub: any) => {
    try {
      const data = await apiFetchUser(sub)
      return data
    } catch (e) {
      console.error("fetchUser: ", e)
      deleteProfileFromSessionStorage()
    }
  }

  const updateUser = (userData: UserType | null) => {
    if (userData) {
      syncProfileWithSessionStorage({ ...user, ...userData })
      setUser({ ...user, ...userData })
      return
    }
    const userStr = getProfileFromSessionStorage()
    const userRole = (typeof window !== undefined
      ? window.sessionStorage.getItem("UserRole")
      : null) as RoleType
    let localData: string | null = null
    if (userStr) {
      localData = JSON.parse(userStr)
      setUser(localData as UserType)
      setRole(userRole)
    }
    Auth.currentAuthenticatedUser()
      .then(cognitoUserData => {
        try {
          const group =
            cognitoUserData.signInUserSession.idToken.payload["cognito:groups"]
          const roleStr = group ? group[0] : "Patient"
          setRole(roleStr)
          window.sessionStorage.setItem("UserRole", roleStr)
          const { attributes } = cognitoUserData
          const { sub, email } = attributes
          const isAdmin = roleStr && roleStr === "Admin"

          fetchUserData(sub).then(data => {
            if (data) {
              const equal = isEqual({ ...data, email }, localData)
              if (!equal) {
                setUser({ ...data, email })
                syncProfileWithSessionStorage({ ...data, email })
              }
            } else {
              setUser(makeUserData(null, email, sub))
              syncProfileWithSessionStorage(makeUserData(null, email, sub))
            }
            if (activeUri && activeLink?.adminOnly && !isAdmin) {
              navigate("/404")
            }
          })
        } catch (e) {
          console.log("Authenticate User:", e)
          deleteProfileFromSessionStorage()
        }
      })
      .catch(e => {
        deleteProfileFromSessionStorage()
        console.log("error: ", e)
        if (
          activeUri &&
          activeLink?.loginRequired &&
          typeof window !== undefined
        ) {
          navigate(
            `/login?returnUrl=${encodeURIComponent(window.location.pathname)}`
          )
        }
      })
  }

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
    updateUser(null)
  }, [])

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement<ChildProps>(child, {
        loggedInUser: user,
        updateUser: updateUser,
      })
    }
    return child
  })

  return (
    <Container style={containerStyles}>
      <ThemeProvider theme={theme}>
        <Header
          links={links}
          pageName={pageName || ""}
          activeUri={activeUri}
          loggedInUser={user}
          logout={logout}
          subPageRightNav={subPageRightNav}
          role={role}
        />
        <Content className={className}>
          <Wrapper>{childrenWithProps}</Wrapper>
        </Content>
        <Footer loggedInUser={user} />
      </ThemeProvider>
    </Container>
  )
}

export default Layout
