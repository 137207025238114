/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          isCustom
        }
        motivations {
          text
          ref
          isCustom
        }
        obstacles {
          text
          ref
          isCustom
        }
        strategies {
          text
          ref
          isCustom
        }
        executions {
          text
          ref
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const getUserCalendar = /* GraphQL */ `
  query GetUserCalendar($filter: GetUserCalendarInput) {
    getUserCalendar(filter: $filter) {
      events {
        id
        startDate
        endDate
        eventType
      }
      symptoms {
        id
        symptomDate
        symptomType {
          id
          name
          symptomQuestion
          choices {
            id
            icon
            name
            color
          }
        }
        value {
          id
          icon
          name
          color
        }
      }
    }
  }
`
export const getForumThreads = /* GraphQL */ `
  query GetForumThreads($limit: Int, $nextToken: String) {
    getForumThreads(limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        author {
          id
          name
        }
        participants {
          id
          username
          fullName
          dateOfBirth
          mobileNumber
          weight
          height
          bmi
          goals {
            id
            goal {
              text
              ref
              isCustom
            }
            motivations {
              text
              ref
              isCustom
            }
            obstacles {
              text
              ref
              isCustom
            }
            strategies {
              text
              ref
              isCustom
            }
            executions {
              text
              ref
              isCustom
            }
            percentage
            isCompleted
            createdOn
            completedOn
          }
          badges {
            name
            icon
            description
            isAchieved
          }
          profilePicture {
            bucket
            region
            key
          }
          doctors {
            id
            fullName
            specialization
            emailAddress
            phoneNumber
            prescriptions {
              dailyFrequency
              medicineName
            }
            appointments
            report {
              bucket
              region
              key
            }
          }
          created_at
          last_updated_at
          bookmarkedArticles
          selectedSelfAssessment {
            id
            selectedValue
          }
          qplDataz {
            id
            selectedValue
            notes
          }
          consent
          additional_data
        }
        posts {
          id
          threadId
          parentId
          text
          author {
            id
            name
          }
          attachments {
            mimeType
            attachmentLocation {
              bucket
              region
              key
            }
          }
          isLiked
          lastUpdatedOn
          createdOn
        }
        views
        lastUpdatedOn
        createdOn
      }
      nextToken
    }
  }
`
export const getSingleForumThread = /* GraphQL */ `
  query GetSingleForumThread($id: ID!) {
    getSingleForumThread(id: $id) {
      id
      title
      text
      author {
        id
        name
      }
      participants {
        id
        username
        fullName
        dateOfBirth
        mobileNumber
        weight
        height
        bmi
        goals {
          id
          goal {
            text
            ref
            isCustom
          }
          motivations {
            text
            ref
            isCustom
          }
          obstacles {
            text
            ref
            isCustom
          }
          strategies {
            text
            ref
            isCustom
          }
          executions {
            text
            ref
            isCustom
          }
          percentage
          isCompleted
          createdOn
          completedOn
        }
        badges {
          name
          icon
          description
          isAchieved
        }
        profilePicture {
          bucket
          region
          key
        }
        doctors {
          id
          fullName
          specialization
          emailAddress
          phoneNumber
          prescriptions {
            dailyFrequency
            medicineName
          }
          appointments
          report {
            bucket
            region
            key
          }
        }
        created_at
        last_updated_at
        bookmarkedArticles
        selectedSelfAssessment {
          id
          selectedValue
        }
        qplDataz {
          id
          selectedValue
          notes
        }
        consent
        additional_data
      }
      posts {
        id
        threadId
        parentId
        text
        author {
          id
          name
        }
        attachments {
          mimeType
          attachmentLocation {
            bucket
            region
            key
          }
        }
        isLiked
        lastUpdatedOn
        createdOn
      }
      views
      lastUpdatedOn
      createdOn
    }
  }
`
export const getMyForumActivity = /* GraphQL */ `
  query GetMyForumActivity($limit: Int, $nextToken: String) {
    getMyForumActivity(limit: $limit, nextToken: $nextToken) {
      items {
        isCreate
        threadId
        threadTitle
        summaryText
        parentId
        createdOn
      }
      nextToken
    }
  }
`
export const getPatients = /* GraphQL */ `
  query GetPatients {
    getPatients
  }
`
