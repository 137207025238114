/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const putSymptoms = /* GraphQL */ `
  mutation PutSymptoms($submitted: [PutSymptomsInput]) {
    putSymptoms(submitted: $submitted) {
      id
      symptomDate
      symptomType {
        id
        name
        symptomQuestion
        choices {
          id
          icon
          name
          color
        }
      }
      value {
        id
        icon
        name
        color
      }
    }
  }
`
export const putUserProfile = /* GraphQL */ `
  mutation PutUserProfile($submitted: PutUserProfileInput) {
    putUserProfile(submitted: $submitted) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const putDoctors = /* GraphQL */ `
  mutation PutDoctors($submitted: [DoctorInput]) {
    putDoctors(submitted: $submitted) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const putGoals = /* GraphQL */ `
  mutation PutGoals($userGoal: UserGoalInput) {
    putGoals(userGoal: $userGoal) {
      id
      goal {
        text
        ref
        iconTag
        isCustom
      }
      motivations {
        text
        ref
        iconTag
        isCustom
      }
      obstacles {
        text
        ref
        iconTag
        isCustom
      }
      strategies {
        text
        ref
        iconTag
        isCustom
      }
      executions {
        text
        ref
        iconTag
        isCustom
      }
      percentage
      isCompleted
      createdOn
      completedOn
    }
  }
`
export const putProfilePicture = /* GraphQL */ `
  mutation PutProfilePicture($submitted: S3ObjectInput) {
    putProfilePicture(submitted: $submitted) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const putPhysicalAttributes = /* GraphQL */ `
  mutation PutPhysicalAttributes($weight: Float, $height: Float) {
    putPhysicalAttributes(weight: $weight, height: $height) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const patchUserPassword = /* GraphQL */ `
  mutation PatchUserPassword($submitted: PatchUserPasswordInput) {
    patchUserPassword(submitted: $submitted)
  }
`
export const postNewForumThread = /* GraphQL */ `
  mutation PostNewForumThread($submitted: PostNewForumThreadInput) {
    postNewForumThread(submitted: $submitted) {
      id
      title
      text
      author {
        id
        name
      }
      participants {
        id
        username
        fullName
        dateOfBirth
        mobileNumber
        weight
        height
        bmi
        goals {
          id
          goal {
            text
            ref
            iconTag
            isCustom
          }
          motivations {
            text
            ref
            iconTag
            isCustom
          }
          obstacles {
            text
            ref
            iconTag
            isCustom
          }
          strategies {
            text
            ref
            iconTag
            isCustom
          }
          executions {
            text
            ref
            iconTag
            isCustom
          }
          percentage
          isCompleted
          createdOn
          completedOn
        }
        badges {
          name
          icon
          description
          isAchieved
        }
        profilePicture {
          bucket
          region
          key
        }
        doctors {
          id
          fullName
          specialization
          emailAddress
          phoneNumber
          prescriptions {
            dailyFrequency
            medicineName
          }
          appointments
          report {
            bucket
            region
            key
          }
        }
        created_at
        last_updated_at
        bookmarkedArticles
        selectedSelfAssessment {
          id
          selectedValue
        }
        qplDataz {
          id
          selectedValue
          notes
        }
        consent
        additional_data
      }
      posts {
        id
        threadId
        parentId
        text
        author {
          id
          name
        }
        attachments {
          mimeType
          attachmentLocation {
            bucket
            region
            key
          }
        }
        isLiked
        lastUpdatedOn
        createdOn
      }
      views
      lastUpdatedOn
      createdOn
    }
  }
`
export const putForumThread = /* GraphQL */ `
  mutation PutForumThread($submitted: PutForumThreadInput) {
    putForumThread(submitted: $submitted) {
      id
      title
      text
      author {
        id
        name
      }
      participants {
        id
        username
        fullName
        dateOfBirth
        mobileNumber
        weight
        height
        bmi
        goals {
          id
          goal {
            text
            ref
            iconTag
            isCustom
          }
          motivations {
            text
            ref
            iconTag
            isCustom
          }
          obstacles {
            text
            ref
            iconTag
            isCustom
          }
          strategies {
            text
            ref
            iconTag
            isCustom
          }
          executions {
            text
            ref
            iconTag
            isCustom
          }
          percentage
          isCompleted
          createdOn
          completedOn
        }
        badges {
          name
          icon
          description
          isAchieved
        }
        profilePicture {
          bucket
          region
          key
        }
        doctors {
          id
          fullName
          specialization
          emailAddress
          phoneNumber
          prescriptions {
            dailyFrequency
            medicineName
          }
          appointments
          report {
            bucket
            region
            key
          }
        }
        created_at
        last_updated_at
        bookmarkedArticles
        selectedSelfAssessment {
          id
          selectedValue
        }
        qplDataz {
          id
          selectedValue
          notes
        }
        consent
        additional_data
      }
      posts {
        id
        threadId
        parentId
        text
        author {
          id
          name
        }
        attachments {
          mimeType
          attachmentLocation {
            bucket
            region
            key
          }
        }
        isLiked
        lastUpdatedOn
        createdOn
      }
      views
      lastUpdatedOn
      createdOn
    }
  }
`
export const removeForumThread = /* GraphQL */ `
  mutation RemoveForumThread($id: ID!) {
    removeForumThread(id: $id)
  }
`
export const postForumPost = /* GraphQL */ `
  mutation PostForumPost($threadId: ID, $submitted: PostForumPostInput) {
    postForumPost(threadId: $threadId, submitted: $submitted)
  }
`
export const postGoalFollowup = /* GraphQL */ `
  mutation PostGoalFollowup($submitted: PostGoalFollowupInput) {
    postGoalFollowup(submitted: $submitted) {
      id
      goalFollowupDate
      stepsTaken
      weightLost
      fruitsEaten
    }
  }
`
export const postBookmarkedArticles = /* GraphQL */ `
  mutation PostBookmarkedArticles($bookmarkedArticles: [String]) {
    postBookmarkedArticles(bookmarkedArticles: $bookmarkedArticles) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const postSelectedSelfAssessment = /* GraphQL */ `
  mutation PostSelectedSelfAssessment(
    $selectedSelfAssessment: [SelectedSelfAssessmentInput]
  ) {
    postSelectedSelfAssessment(
      selectedSelfAssessment: $selectedSelfAssessment
    ) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const postQplDataz = /* GraphQL */ `
  mutation PostQplDataz($qplDataz: [QplDatazInput]) {
    postQplDataz(qplDataz: $qplDataz) {
      id
      username
      fullName
      dateOfBirth
      mobileNumber
      weight
      height
      bmi
      goals {
        id
        goal {
          text
          ref
          iconTag
          isCustom
        }
        motivations {
          text
          ref
          iconTag
          isCustom
        }
        obstacles {
          text
          ref
          iconTag
          isCustom
        }
        strategies {
          text
          ref
          iconTag
          isCustom
        }
        executions {
          text
          ref
          iconTag
          isCustom
        }
        percentage
        isCompleted
        createdOn
        completedOn
      }
      badges {
        name
        icon
        description
        isAchieved
      }
      profilePicture {
        bucket
        region
        key
      }
      doctors {
        id
        fullName
        specialization
        emailAddress
        phoneNumber
        prescriptions {
          dailyFrequency
          medicineName
        }
        appointments
        report {
          bucket
          region
          key
        }
      }
      created_at
      last_updated_at
      bookmarkedArticles
      selectedSelfAssessment {
        id
        selectedValue
      }
      qplDataz {
        id
        selectedValue
        notes
      }
      consent
      additional_data
    }
  }
`
export const removeForumPost = /* GraphQL */ `
  mutation RemoveForumPost($id: ID!) {
    removeForumPost(id: $id)
  }
`
export const removeSymptom = /* GraphQL */ `
  mutation RemoveSymptom($ids: [ID!]) {
    removeSymptom(ids: $ids)
  }
`
export const postArticleFeedback = /* GraphQL */ `
  mutation PostArticleFeedback($articleFeedback: ArticleFeedbackInput) {
    postArticleFeedback(articleFeedback: $articleFeedback) {
      articleId
      isHelpful
      feedbacks
      createdOn
    }
  }
`
