import { Button } from "components/buttons"
import { navigate } from "gatsby"
import ChevLeftSvg from "images/chevron-left.inline.svg"
import logoImg from "images/logo.svg"
import LogoSvg from "images/logo.inline.svg"
import _ from "lodash"
import { ISiteLink } from "models/ISiteLink"
import React, { useState } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { FaRegBell } from "react-icons/fa"
import { GiHamburgerMenu } from "react-icons/gi"
import { RiDashboard3Line } from "react-icons/ri"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import CollapseMenu from "./CollapseMenu"
import ProfileButton from "./ProfileButton"
import avatar from "../../../images/person-1.jpeg"
import { IPageContext, UserType } from "api/types"
import theme from "components/layout/theme"
import { Link } from "gatsby"
import Breadcrumbs from "../../Breadcrumbs"

const Container = styled(Navbar)`
  height: 106px;
  background-color: white !important;
  justify-content: center;
  z-index: 1000;
  border-bottom: 1px solid lightgrey;

  @media (max-width: ${breakpoints.tabletLandscape}px) {
    height: 60px;
    ${props => {
      if (props.shadow) {
        return css`
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        `
      }
    }}
  }
`
const Wrapper = styled.div`
  width: 100%;
  /* margin-top: -52px; */
  max-width: 1180px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.extraLargeDesktop}px) {
    padding: 0 4rem;
    max-width: 1552px;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 2rem;
  }
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    padding: 0 1rem;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0;
  }
`
const LogoImage = styled.img`
  margin: 0;
  cursor: pointer;
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    display: none;
  }
`
const LogoIcon = styled(LogoSvg)`
  /* margin-bottom: 20px; */

  width: 50px;
  margin-left: 15px;
`
const LeftNav = styled(Nav)`
  margin-left: 48px;
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    display: none;
  }
`
const RightNav = styled(Nav)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    ${(props: any) => {
      if (props.$hideOnPhone) {
        return css`
          display: none;
        `
      }
    }}
  }
`
const NavLink = styled(Link)`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  text-align: center;
  justify-content: center;
  color: ${props => props.theme.colors.secondary} !important;
  padding: 9px 15px 9px 15px !important;

  &:hover {
    background-color: ${props => props.theme.colors.grayBase} !important;
    border-radius: 20px;
    text-decoration: none;
  }
`
const BottomBorder = styled.div`
  width: 32px;
  border-bottom: 3px solid #ec4d7b;
  margin-top: 2px;
  margin-left: 15px;
`
const Toggle: any = styled.div`
  display: none;
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    display: flex;
    align-items: center;
    ${(props: any) => {
      if (props.isSubpage) {
        return css`
          display: none;
        `
      }
    }}
  }
  & span {
    font-family: Open Sans;
    font-size: 18px;
    line-height: 22px;
    color: #979797;
    margin-left: 12px;
    margin-top: -1px;
  }
`
const ToggleIcon = styled(GiHamburgerMenu)`
  color: ${theme.colors.primary};
`
const NotificationBtn = styled.div`
  position: relative;
  height: 36px;
  width: 36px;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    padding: 8px;
    border-radius: 50%;
    background: rgba(51, 51, 51, 0.05);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`
const BellIcon = styled(FaRegBell)`
  height: 36px;
  width: 36px;
  stroke: #666;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    height: 20px;
    width: 20px;
  }
`
const Badge = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background: #ec4d7b;
  color: white;
  font-family: Open Sans;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #ffffff;
  padding: 8px;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    top: -4px;
    right: -4px;
  }
`
const DashWrap = styled.div`
  display: none;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: flex;
  }
`
const DashButton = styled(Button)`
  margin-top: auto;
  width: fit-content;
  display: flex;
  align-self: center;
  align-items: center;
  height: 36px;
`
const DashboardIcon = styled(RiDashboard3Line)`
  margin-right: 4px;
`
const ButtonContainer = styled.div`
  border-right: 1px solid lightgrey;
  padding-right: 10px;
`
const LoginContainer = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
const AuthButton = styled(Button)`
  margin-left: 10px;
  height: 30px;
  align-items: center;
  display: flex;
`
const BackButton: any = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${(props: any) => {
    if (!props.isSubpage) {
      return css`
        display: none;
      `
    }
  }}
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: none;
  }
`
const LeftChevIcon = styled(ChevLeftSvg)`
  color: ${props => props.theme.colors.primary};
  height: 18px;
`

const renderLeftNav = (
  links: ISiteLink[],
  activeUri: string,
  isAdmin: boolean
) => {
  let filteredLinks = links
  filteredLinks = links.filter(link => !link.onlyMobile)
  if (!isAdmin) {
    filteredLinks = filteredLinks.filter(link => !link.adminOnly)
  }

  const menuLinks = _.filter(filteredLinks, { menu: true })

  return menuLinks.map(link => {
    return (
      <div key={link.text}>
        <NavLink to={link.uri}>{link.text}</NavLink>
        {link.uri === activeUri && <BottomBorder />}
      </div>
    )
  })
}

interface Props {
  // loggedIn: boolean
  loggedInUser?: UserType
  logout: Function
  // pageContext: IPageContext
  links: ISiteLink[]
  pageName: string
  activeUri: string
  subPageRightNav?: any
  role?: "Admin" | "Patient" | null
}

const Header: React.FC<Props> = props => {
  const {
    loggedInUser,
    logout,
    links,
    pageName,
    activeUri,
    subPageRightNav,
    role,
  } = props

  const [menuIsOpen, setMenuOpen] = useState(false)

  const activeLink = _.find(links, link => link.uri === activeUri)

  const toggleMenu = () => setMenuOpen(!menuIsOpen)

  const isAdmin = role === "Admin"

  const isSubpage =
    (activeLink && activeLink.isSubpage) ||
    (typeof window !== `undefined`
      ? window.location.pathname.split("/").filter(i => i).length >= 3
      : false)
  const notificationCount = 2
  const profilePhoto = loggedInUser?.profilePicture || avatar
  const loggedIn = !!(loggedInUser && loggedInUser.id)

  const isPhoneView =
    typeof window !== `undefined` && window.innerWidth <= breakpoints.tablet

  console.log("phone view", isPhoneView)

  return (
    <Container fixed="top" bg="light" expand="lg" shadow={isSubpage ? 0 : 1}>
      <Wrapper>
        <LogoImage
          alt="Early menopause"
          src={logoImg}
          onClick={() => navigate("/")}
        />

        <Toggle isSubpage={isSubpage} onClick={toggleMenu}>
          <ToggleIcon />

          <span>{pageName}</span>
          {!pageName && (
            <BackButton isSubpage={!isSubpage} onClick={() => navigate(-1)}>
              <span
                style={{
                  color: "gray",
                  fontSize: "18px",
                  // paddingLeft: "10px",
                  marginLeft: "0px",
                }}
              >
                Article
              </span>
            </BackButton>
          )}
        </Toggle>
        <CollapseMenu
          show={menuIsOpen}
          toggle={toggleMenu}
          items={links}
          loggedIn={loggedIn}
          logout={logout}
          isAdmin={isAdmin}
        />
        <LeftNav>{renderLeftNav(links, activeUri, isAdmin)}</LeftNav>
        {!loggedIn && (
          <RightNav $hideOnPhone={isSubpage} className="ml-auto">
            <ButtonContainer>
              <AuthButton onClick={() => navigate("/signup")}>
                Sign Up
              </AuthButton>
            </ButtonContainer>
            {!isPhoneView ? (
              <AuthButton onClick={() => navigate("/login")} outline>
                My dashboard
              </AuthButton>
            ) : (
              <LogoIcon onClick={() => navigate("/")} />
            )}
          </RightNav>
        )}
        {loggedIn && (
          <RightNav $hideOnPhone={isSubpage} className="ml-auto">
            <DashWrap>
              <DashButton onClick={() => navigate("/dashboard")}>
                <DashboardIcon />
                Dashboard
              </DashButton>
            </DashWrap>
            <ProfileButton
              name={loggedInUser?.fullName || ""}
              imgUrl={profilePhoto}
              logout={logout}
            />
            {/* <NotificationBtn>
              <BellIcon />
              {notificationCount > 0 && <Badge>{notificationCount}</Badge>}
            </NotificationBtn> */}
          </RightNav>
        )}
        {isSubpage && subPageRightNav ? (
          <RightNav>{subPageRightNav}</RightNav>
        ) : null}
      </Wrapper>
    </Container>
  )
}

export default Header
