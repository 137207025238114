import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import mchriLogo from "images/mchri-logo.png"
import monashLogo from "images/monashhealth-logo.jpeg"
import facebookLogo from "images/facebook-logo.png"
import monashUniLogo from "images/monash-university-logo.png"

import moment from "moment"
import {
  MCHRI_ExternalLink,
  MonashFacebook_ExternalLink,
  MonashHealth_ExternalLink,
  MonashUniversity_ExternalLink,
  MonashPrivacyPolicy_ExternalLink,
} from "../../../utils/urls"
import IconLink from "../../IconLink"
import { breakpoints } from "../../../utils"
import { UserType } from "api/types"
import {
  Dashboard_InternalLink,
  DiscoveryPage_InternalLink,
  ManageLifeStylePage_InternalLink,
  Discussion_InternalLink,
} from "utils/urls"

const Container = styled.div`
  padding-top: 136px;
  background-color: white;
`
const Disclaimer = styled(Row)`
  font-family: Open Sans;
  font-size: 16px;
  color: white;
  display: flex;
  margin-left: 2px;
  width: 100%;
  margin-top: 60px;

  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: center;
    text-align: center;
  }
`
const DisclaimerLeft = styled(Col)``

const DisclaimerRight = styled(Col)`
  & h3 {
    margin-left: -10px;
  }
  & img {
  }
`

const LogoRow = styled(Row)`
  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: center;
  }
`

const LogoCol = styled(Col)`
  margin: 5px;
  border-radius: 10px;
  height: 70px;
  // cursor: pointer;
  min-width: 130px;
  max-width: 130px;
  background-color: #eeeeee;
`

const MchriImage = styled.img`
  height: 70px;
  object-fit: contain;
`
const MonashImage = styled.img`
  height: 70px;
  object-fit: contain;
`
const MonashUniImage = styled.img`
  height: 30px;
  margin-top: 23px;
  object-fit: contain;
`
const FacebookRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: center;
  }
`
const FacebookCol = styled(Col)`
  background-color: ${props => props.theme.colors.blue};
  max-width: 300px;
  border-radius: 20px;
  color: white;
  height: 40px;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: end;
  }
`
const FacebookLogo = styled.img`
  height: 25px;
  object-fit: contain;
  margin-top: 28px;
  margin-right: 15px;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.secondary} !important;
  padding-bottom: 20px;
`
const Content = styled(Row)`
  width: 100%;
  padding-bottom: 20px;
  /* max-width: 1180px; */
  max-width: 1260px;
  // @media (min-width: ${breakpoints.extraLargeDesktop}px) {
  //   max-width: 1552px;
  // }
  @media (max-width: ${breakpoints.extraLargeDesktop}px) {
    padding: 0 4rem;
    max-width: 1552px;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 0 2rem;
  }
  @media (max-width: ${breakpoints.tabletLandscape}px) {
    padding: 0 1rem;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    padding: 0;
    width: 90%;
  }
  display: flex;
  justify-content: space-between;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`
const Section = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: 30px;
`
const Links = styled.a`
  text-decoration: none !important;
  color: #ffffff;
  margin-bottom: 16px;
  &:hover {
    color: #afafaf;
  }
`
const Text = styled.div`
  color: #ffffff;
`
const CopyText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.75);
  margin-top: auto;
  margin-bottom: 18px;
`
const IconList = styled.div`
  display: flex;
`

const allLinks = {
  site: [
    { label: "Dashboard", url: Dashboard_InternalLink, loggedInRequired: true },
    { label: "Discover", url: DiscoveryPage_InternalLink },
    { label: "Topics", url: "#topics" },
    { label: "Lifestyle", url: ManageLifeStylePage_InternalLink },
    { label: "Discussion forum", url: Discussion_InternalLink },
  ],
  tnc: [
    { label: "Terms of use", url: "#" },
    { label: "Privacy Policy", url: "#" },
  ],
  twitter: "#",
  facebook: "#",
  instagram: "#",
}

const iconStyle = {
  marginRight: "20px",
  marginLeft: "-4px",
  marginTop: "12px",
}

interface Props {
  loggedInUser?: UserType | null
}

const FooterContent = () => {
  const [show, setShow] = useState(false)
  if (!show) {
    return (
      <p
        style={{ fontSize: "15px", fontWeight: "normal", lineHeight: "normal" }}
      >
        This information is informed by multidisciplinary clinical expertise in
        menopause and early menopause. The Monash Centre for Health Research and
        Implementation (MCHRI) and Monash University provide the AskEM
        application (the app). The{" "}
        <a
          href={MonashPrivacyPolicy_ExternalLink}
          target="_blank"
          rel="noreferrer"
        >
          <strong className="hover:underline cursor-pointer">
            terms of use & privacy policy
          </strong>
        </a>{" "}
        apply to your use of the app, and we encourage you to read them ... {""}
        <span
          className="hover:underline"
          onClick={() => setShow(!show)}
          style={{ fontWeight: "bold", cursor: "pointer" }}
        >
          Read more
        </span>
      </p>
    )
  } else {
    return (
      <p
        style={{ fontSize: "15px", fontWeight: "normal", lineHeight: "normal" }}
      >
        This information is informed by multidisciplinary clinical expertise in
        menopause and early menopause. The Monash Centre for Health Research and
        Implementation (MCHRI) and Monash University provide the AskEM
        application (the app). The{" "}
        <a
          href={MonashPrivacyPolicy_ExternalLink}
          target="_blank"
          rel="noreferrer"
        >
          <strong className="hover:underline cursor-pointer">
            terms of use & privacy policy
          </strong>
        </a>{" "}
        apply to your use of the app, and we encourage you to read them <br />
        <br />
        The app is designed to be informative and educational, not to provide
        specific medical advice or to replace individual health practitioner
        advice. MCHRI and Monash explicitly do not warrant the accuracy of the
        information contained in this app. MCHRI and Monash do not give medical
        advice, nor provide medical or diagnostic services. Medical information
        changes rapidly. Neither we nor our authors guarantee that the content
        covers all possible uses, directions, precautions, drug interactions, or
        adverse effects that may be associated with any therapeutic treatments.
        Your reliance upon information and content obtained by you at or through
        this app is solely at your own risk. Neither we nor our authors assume
        any liability or responsibility for damage or injury (including death)
        to you, other persons or property arising from any use of any product,
        information, idea or instruction contained in the content or services
        provided to you. {""}
        <span
          className="hover:underline"
          onClick={() => setShow(!show)}
          style={{ fontWeight: "bold", cursor: "pointer" }}
        >
          Read less
        </span>
      </p>
    )
  }
}

const Footer: React.FC<Props> = ({ loggedInUser }) => {
  const renderLinks = (links: any) => {
    return (
      <>
        {links.map((link: any) => {
          return (
            <Links
              href={
                !loggedInUser && link.loggedInRequired
                  ? `/login?returnUrl=${encodeURIComponent(link.url)}`
                  : link.url
              }
              key={link.label}
            >
              {link.label}
            </Links>
          )
        })}
      </>
    )
  }
  return (
    <Container>
      <Wrapper>
        <Content>
          <Disclaimer>
            <DisclaimerLeft xs={12} md={8} sm={12}>
              <h3 className=" mb-2 no-underline">
                <a className="no-underline" href="/">
                  <span className="text-white text-4xl">AskEM</span>
                </a>
              </h3>

              <p className="mb-4">
                <strong className="text-sm">Ask Early Menopause</strong> is your
                personal guide to early menopause.
              </p>
              <p className="text-lg mb-3">
                <strong>Disclaimer</strong>
              </p>
              <FooterContent />
              <p style={{ fontSize: "15px", fontWeight: "normal" }}>
                All rights reserved &copy; {moment(new Date()).format("YYYY")}
              </p>
            </DisclaimerLeft>
            <DisclaimerRight xs={12} md={4} sm={12}>
              <h3 className="mb-3">Powered by</h3>
              <LogoRow>
                <LogoCol>
                  <MchriImage
                    alt="Monash Centre for Health Research and Implementation (MCHRI)"
                    src={mchriLogo}
                    // onClick={() => window.open(MCHRI_ExternalLink, "_blank")}
                  />
                </LogoCol>
                <LogoCol>
                  <MonashImage
                    alt="Monash Health"
                    src={monashLogo}
                    // onClick={() => window.open(MonashHealth_ExternalLink, "_blank") }
                  />
                </LogoCol>
                <LogoCol>
                  <MonashUniImage
                    alt="Monash University"
                    src={monashUniLogo}
                    // onClick={() => window.open(MonashUniversity_ExternalLink, "_blank") }
                  />
                </LogoCol>
              </LogoRow>
              <FacebookRow>
                <FacebookCol
                  onClick={() =>
                    window.open(MonashFacebook_ExternalLink, "_blank")
                  }
                >
                  <FacebookLogo src={facebookLogo} /> Follow us on Facebook
                </FacebookCol>
              </FacebookRow>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "normal",
                  lineHeight: "normal",
                }}
              >
                Get in touch with us at askem.admin@monash.edu <br />
              </p>
            </DisclaimerRight>
          </Disclaimer>
        </Content>
      </Wrapper>
    </Container>
  )
}

export default Footer
