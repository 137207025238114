export const breakpoints = {
  phone: 420,
  tablet: 767,
  tabletLandscape: 1024,
  desktop: 1280,
  largeDesktop: 1366,
  extraLargeDesktop: 1920,
}

export const IEVersion = () => {
  const userAgent = navigator.userAgent
  const isIE =
    userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1
  const isEdge = userAgent.indexOf("Edge") > -1 && !isIE
  const isIE11 =
    userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1
  if (isIE) {
    const reIE = new RegExp("MSIE (\\d+\\.\\d+);")
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp["$1"])
    if (fIEVersion == 7) {
      return 7
    } else if (fIEVersion == 8) {
      return 8
    } else if (fIEVersion == 9) {
      return 9
    } else if (fIEVersion == 10) {
      return 10
    } else {
      return 6
    }
  } else if (isEdge) {
    return "edge"
  } else if (isIE11) {
    return 11
  } else {
    return -1
  }
}

export const isBrowser = () => {
  return typeof window !== `undefined`
}

export const captureImage = (url: string, fn: Function) => {
  let video = document.createElement("video")
  video.setAttribute("width", "200")
  video.setAttribute("height", "130")
  video.setAttribute("crossOrigin", "Anonymous")
  video.setAttribute("controls", "controls")
  video.setAttribute("autoplay", "autoplay")
  video.setAttribute("src", url)
  const canvas = document.createElement("canvas")
  video.addEventListener("loadeddata", function () {
    canvas.width = video.width
    canvas.height = video.height
    canvas.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height)
    fn(canvas.toDataURL("image/png"))
  })
}
