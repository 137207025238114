import _ from "lodash"
import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import LogoSvg from "images/logo.inline.svg"
import { GrClose } from "react-icons/gr"
import { FiLogOut } from "react-icons/fi"
import { BiUser } from "react-icons/bi"
import { FaRegBookmark } from "react-icons/fa"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  overflow-y: scroll;
  transition: -webkit-transform 0.3s ease-out 0s;
  will-change: transform;
  transform: translateX(0);
`
const Menu = styled.div`
  position: relative;
  margin-bottom: 24px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
const LogoIcon = styled(LogoSvg)`
  margin-bottom: 20px;
  width: 94px;
`
const CrossIcon: any = styled(GrClose)``
const Nav = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`
const NavLink: any = styled.a`
  margin-bottom: 30px;
  font-family: Open Sans;
  font-size: 18px;
  line-height: 12px;
  letter-spacing: 0.03em;
  color: ${props => props.theme.colors.secondary};
  width: 100%;
  display: flex;
`
const LoggedInNav = styled.div`
  display: flex;
  flex-direction: column;
`
const BookmarkIcon = styled(FaRegBookmark)`
  margin-left: auto;
  stroke: #979797;
  height: 28px;
  width: 28px;
`
const LogoutIcon = styled(FiLogOut)`
  margin-left: auto;
  stroke: #979797;
`
const UserIcon = styled(BiUser)`
  margin-left: auto;
  stroke: #979797;
  height: 28px;
  width: 28px;
`
const PoweredBy = styled.p`
  color: ${props => props.theme.colors.secondary};
  font-size: 13px;
  margin-top: 30px;
`

interface Props {
  show?: boolean
  items: object[]
  toggle: Function
  logout: Function
  loggedIn: boolean
  isAdmin: boolean
}

const renderItems = (
  items: any,
  isAdmin: boolean,
  loggedIn: boolean,
  toggle: Function
) => {
  let filteredLinks = items
  if (!isAdmin) {
    filteredLinks = items.filter((link: any) => !link.adminOnly)
  }
  if (!loggedIn) {
    filteredLinks = filteredLinks.filter((link: any) => !link.loginRequired)
    filteredLinks.push({ uri: "/login", text: "My dashboard", menu: true })
  }
  const menuLinks = _.filter(filteredLinks, { menu: true })

  return menuLinks.map((item: any) => {
    return (
      <div key={item.text}>
        <NavLink
          onClick={() => item.uri.includes("#") && toggle()}
          href={item.uri}
        >
          {item.text}
        </NavLink>
      </div>
    )
  })
}

const CollapseMenu: React.FC<Props> = ({
  show,
  toggle,
  items,
  loggedIn,
  logout,
  isAdmin,
}) => {
  return (
    <Container
      style={{ transform: show ? "translateX(0%)" : "translateX(-100%)" }}
    >
      <Menu>
        <Header>
          <LogoIcon onClick={() => navigate("/")} />
          <CrossIcon onClick={toggle} />
        </Header>
        <Nav>{renderItems(items, isAdmin, loggedIn, toggle)}</Nav>
      </Menu>
      {loggedIn && (
        <LoggedInNav>
          <NavLink
            onClick={() => navigate("/profile")}
            style={{ color: "#979797" }}
          >
            My Profile
            <UserIcon />
          </NavLink>
          <NavLink onClick={logout} style={{ color: "#979797" }}>
            Logout
            <LogoutIcon />
          </NavLink>
        </LoggedInNav>
      )}
      <PoweredBy>
        Powered By <br /> Monash Centre for Health Research & <br />
        Implementation <br />
        <strong>(MRCHRI)</strong>
      </PoweredBy>
    </Container>
  )
}

export default CollapseMenu
