import { RemoveForumThreadMutation } from "./graphql/API"
import { removeForumThread } from "./../graphql/mutations"
import { API } from "aws-amplify"
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql"
type AuthMode =
  | GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  | GRAPHQL_AUTH_MODE.API_KEY
import {
  getUser,
  getForumThreads,
  getMyForumActivity,
  getUserCalendar,
  // getQuestionPromptList,
  // getSelfAssessmentQuiz,
  getSingleForumThread,
  getPatients,
} from "../graphql/queries"
import {
  putUserProfile,
  putGoals,
  putDoctors,
  putPhysicalAttributes,
  putSymptoms,
  postSelectedSelfAssessment,
  postQplDataz,
  postNewForumThread,
  postForumPost,
  removeForumPost,
  postBookmarkedArticles,
  removeSymptom,
  postArticleFeedback,
  putForumThread,
} from "../graphql/mutations"
import {
  GetUserQueryVariables,
  PutUserProfileInput,
  GetForumThreadsQueryVariables,
  GetMyForumActivityQueryVariables,
  GetUserCalendarInput,
  GetUserCalendarQuery,
  // GetSelfAssessmentQuizQuery,
  UserGoalInput,
  PutGoalsMutation,
  DoctorInput,
  PutPhysicalAttributesMutation,
  PutSymptomsInput,
  PutSymptomsMutation,
  SelectedSelfAssessmentInput,
  PostSelectedSelfAssessmentMutation,
  QplDatazInput,
  PostQplDatazMutation,
  PostNewForumThreadInput,
  PostNewForumThreadMutation,
  RemoveForumPostMutation,
  GetPatientsQuery,
  ArticleFeedbackInput,
  PostArticleFeedbackMutation,
  RemoveSymptomMutation,
  PostForumPostInput,
  PostForumPostMutation,
  PutForumThreadMutation,
  PutForumThreadInput,
} from "api/graphql/API"
import { QPLNode, UserType, ForumThread, ForumActivity } from "api/types"

/**
 * User Profile
 */
export const apiFetchUser = async (id: string) => {
  const _data = (await API.graphql({
    query: getUser,
    variables: {
      id,
    } as GetUserQueryVariables,
  })) as { data: { getUser: UserType } }

  if (!_data || !_data.data || !_data.data.getUser)
    throw Error("Something went wrong - no data was received.")
  return _data.data.getUser
}
/**
 * put user profile
 */
export const apiPutProfileData = async (params: PutUserProfileInput) => {
  const _data = (await API.graphql({
    query: putUserProfile,
    variables: {
      submitted: params,
    },
  })) as { data: { putUserProfile: UserType } }
  if (!_data || !_data.data || !_data.data.putUserProfile)
    throw Error("Something went wrong - no data was received.")
  return _data.data.putUserProfile
}
export const apiPutPhysicalAttributes = async ({
  weight,
  height,
}: {
  weight: string
  height: string
}) => {
  const _data = (await API.graphql({
    query: putPhysicalAttributes,
    variables: {
      weight,
      height,
    },
  })) as { data: PutPhysicalAttributesMutation }
  if (!_data || !_data.data)
    throw Error("Something went wrong - no data was received.")
  return _data
}
// ============================Dashboard=====================================
/**
 * put goals
 */
export const apiPutGoals = async (userGoal: UserGoalInput) => {
  const _data = (await API.graphql({
    query: putGoals,
    variables: {
      userGoal,
    },
  })) as { data: PutGoalsMutation }

  if (!_data || !_data.data || !_data.data.putGoals)
    throw Error("Something went wrong - no data was received.")
  return _data.data
}

/**
 * put symptoms
 */
export const apiPutSymptoms = async (params: PutSymptomsInput[]) => {
  const _data = (await API.graphql({
    query: putSymptoms,
    variables: {
      submitted: params,
    },
  })) as { data: { putSymptoms: PutSymptomsMutation } }
  if (!_data || !_data.data || !_data.data.putSymptoms) {
    throw Error("Something went wrong - no data was received.")
  }
  return _data.data.putSymptoms
}
/**
 * remove symptoms
 */
export const apiRemoveSymptom = async (ids: string[]) => {
  const _data = (await API.graphql({
    query: removeSymptom,
    variables: {
      ids,
    },
  })) as { data: RemoveSymptomMutation }
  if (!_data || !_data.data || !_data.data.removeSymptom) {
    throw Error("Something went wrong - no data was received.")
  }
  return _data.data.removeSymptom
}
/**
 * put doctors
 */
export const apiPutDoctors = async (submitted: DoctorInput[]) => {
  const _data = (await API.graphql({
    query: putDoctors,
    variables: {
      submitted,
    },
  })) as { data: { putDoctors: UserType } }
  if (!_data || !_data.data) {
    throw Error("Something went wrong - no data was received.")
  }
  return _data.data.putDoctors
}
// =========================Forum api================================
/**
 * get Forum Threads
 */
export const apiFetchForumThreads = async (
  params: GetForumThreadsQueryVariables,
  authMode: AuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
) => {
  const _data = (await API.graphql({
    query: getForumThreads,
    variables: {
      limit: params.limit,
      nextToken: params.nextToken,
    },
    authMode,
  })) as {
    data: {
      getForumThreads: {
        items: ForumThread[] | null
        nextToken: string | null
      }
    }
  }
  if (!_data || !_data.data || !_data.data.getForumThreads)
    throw Error("Something went wrong - no data was received.")
  return _data.data.getForumThreads
}
/**
 *
 * get single forum thread
 * @returns getSingleForumThread
 */
export const apiGetSingleForumThread = async (
  id: string,
  authMode: AuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
) => {
  const _data = (await API.graphql({
    query: getSingleForumThread,
    variables: {
      id,
    },
    authMode,
  })) as {
    data: {
      getSingleForumThread: ForumThread | null
    }
  }
  if (!_data || !_data.data || !_data.data.getSingleForumThread)
    throw Error("Something went wrong - no data was received.")
  return _data.data.getSingleForumThread
}
/**
 *
 * get my forum activity
 * @returns getMyForumActivity
 */
export const apiFetchMyForumActivity = async (
  params: GetMyForumActivityQueryVariables
) => {
  const _data = (await API.graphql({
    query: getMyForumActivity,
    variables: {
      limit: params.limit,
      nextToken: params.nextToken,
    },
  })) as {
    data: {
      getMyForumActivity: {
        items: ForumActivity[] | null
        nextToken: string | null
      }
    }
  }
  if (!_data || !_data.data || !_data.data.getMyForumActivity)
    throw Error("Something went wrong - no data was received.")

  return _data.data.getMyForumActivity
}
/**
 *
 * post new forum thread
 * @returns
 */
export const apiPostNewForumThread = async (
  params: PostNewForumThreadInput
) => {
  const _data = (await API.graphql({
    query: postNewForumThread,
    variables: {
      submitted: params,
    },
  })) as { data: PostNewForumThreadMutation }
  if (!_data || !_data.data || !_data.data.postNewForumThread)
    throw Error("Something went wrong - no data was received.")

  return _data.data.postNewForumThread
}
/**
 *
 * post new forum Post
 * @returns
 */
export const apiPostForumPost = async (
  threadId: string,
  submitted: PostForumPostInput
) => {
  const _data = (await API.graphql({
    query: postForumPost,
    variables: {
      threadId,
      submitted,
    },
  })) as { data: PostForumPostMutation }
  if (!_data || !_data.data || _data.data.postForumPost === undefined)
    throw Error("Something went wrong - no data was received.")

  return _data.data.postForumPost
}
/**
 *
 * remove forum Post
 * @returns
 */
export const apiRemoveNewForumPost = async (id: string) => {
  const _data = (await API.graphql({
    query: removeForumPost,
    variables: {
      id,
    },
  })) as { data: RemoveForumPostMutation }
  if (!_data || !_data.data || !_data.data.removeForumPost)
    throw Error("Something went wrong - no data was received.")

  return _data.data.removeForumPost
}

export const apiPutForumThread = async (params: PutForumThreadInput) => {
  const _data = (await API.graphql({
    query: putForumThread,
    variables: {
      submitted: params,
    },
  })) as { data: PutForumThreadMutation }

  if (!_data || !_data.data || !_data.data.putForumThread) {
    throw Error("Something went wrong - no data was received")
  }
  return _data.data.putForumThread
}

export const apiRemoveForumThread = async (id: string) => {
  const _data = (await API.graphql({
    query: removeForumThread,
    variables: {
      id,
    },
  })) as { data: RemoveForumThreadMutation }

  if (!_data || !_data.data || !_data.data.removeForumThread) {
    throw Error("Something went wrong - no data was received")
  }
  return _data.data.removeForumThread
}

// ============================Dashboard=====================================

/**
 * Dashboard UserCalendar
 */
export const apiFetchUserCalendar = async (data: GetUserCalendarInput) => {
  const _data = (await API.graphql({
    query: getUserCalendar,
    variables: {
      filter: data,
    },
  })) as { data: GetUserCalendarQuery }

  if (!_data || !_data.data)
    throw Error("Something went wrong - no data was received.")
  return _data.data.getUserCalendar
}

// ============================== QPL ===================================
/**
 * get QPL list
 */

export const apiFetchQuestionPromptList = async () => {
  const _data = (await API.graphql({
    query: getQuestionPromptList,
  })) as { data: { getQuestionPromptList: QPLNode[] } }

  if (!_data || !_data.data || !_data.data.getQuestionPromptList)
    throw Error("Something went wrong - no data was received.")

  return _data.data.getQuestionPromptList
}
/**
 * post QPL Data
 * @returns
 */
export const apiPostQplDataz = async (params: QplDatazInput[]) => {
  const _data = (await API.graphql({
    query: postQplDataz,
    variables: {
      qplDataz: params,
    },
  })) as { data: PostQplDatazMutation }

  if (!_data || !_data.data || !_data.data.postQplDataz)
    throw Error("Something went wrong - no data was received.")
  return _data.data.postQplDataz
}

// =======================Self Assessment=======================================
/**
 * get self assessment quiz
 */
export const apiFetchSelfAssessmentQuiz = async () => {
  const _data = (await API.graphql({
    query: getSelfAssessmentQuiz,
  })) as { data: GetSelfAssessmentQuizQuery }

  if (!_data || !_data.data)
    throw Error("Something went wrong - no data was received.")
  return _data.data.getSelfAssessmentQuiz
}
/**
 * post self assessment quiz
 * @param SelectedSelfAssessmentInput[]
 * @returns postSelectedSelfAssessment
 */
export const apiPostSelfAssessmentQuiz = async (
  params: SelectedSelfAssessmentInput[]
) => {
  const _data = (await API.graphql({
    query: postSelectedSelfAssessment,
    variables: {
      selectedSelfAssessment: params,
    },
  })) as { data: PostSelectedSelfAssessmentMutation }

  if (!_data || !_data.data || !_data.data.postSelectedSelfAssessment)
    throw Error("Something went wrong - no data was received.")
  return _data.data.postSelectedSelfAssessment
}
// =======================Article Page=======================================
/**
 * post bookmarked articles
 * @param string[]
 * @returns postBookmarkedArticles
 */
export const apiPostBookmarkedArticles = async (
  bookmarkedArticles: string[]
) => {
  const _data = (await API.graphql({
    query: postBookmarkedArticles,
    variables: {
      bookmarkedArticles,
    },
  })) as { data: { postBookmarkedArticles: UserType } }

  if (!_data || !_data.data || !_data.data.postBookmarkedArticles)
    throw Error("Something went wrong - no data was received.")
  return _data.data.postBookmarkedArticles
}

/**
 * post article feedback
 * @param ArticleFeedbackInput
 * @returns postArticleFeedback
 */
export const apiPostArticleFeedback = async (
  articleFeedback: ArticleFeedbackInput,
  authMode: AuthMode = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
) => {
  const _data = (await API.graphql({
    query: postArticleFeedback,
    variables: {
      articleFeedback,
      authMode,
    },
  })) as { data: PostArticleFeedbackMutation }

  if (!_data || !_data.data || !_data.data.postArticleFeedback)
    throw Error("Something went wrong - no data was received.")
  return _data.data.postArticleFeedback
}

// ==========================Patients========================================
/**
 * get Patients
 * @param
 * @returns getPatients
 */
export const apiGetPatients = async () => {
  const _data = (await API.graphql({
    query: getPatients,
  })) as { data: GetPatientsQuery }

  if (!_data || !_data.data || !_data.data.getPatients)
    throw Error("Something went wrong - no data was received.")
  return _data.data.getPatients
}
