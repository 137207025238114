import React from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "utils"
import { Dropdown } from "react-bootstrap"
import { navigate } from "gatsby"
import { FaRegUser } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"
import Avatar from "react-avatar"
import LogoSvg from "images/logo.inline.svg"
import theme from "components/layout/theme"

const Container = styled(Dropdown)`
  display: none;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: block;
  }
`
const MobileWrapper = styled.div`
  display: block;
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: none;
  }
  display: flex;
  cursor: pointer;
  user-select: none;
`
const Toggle = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
`
const Menu = styled.div`
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  margin-top: 12px !important;
`
const DropdownItem = styled(Dropdown.Item)`
  color: ${props => props.theme.colors.secondary};
  &:focus,
  &:active,
  &:visited {
    background: #e9ecef;
    color: ${props => props.theme.colors.secondary};
  }
`
const ProfileInfo = styled.div`
  display: none;
  color: ${props => props.theme.colors.secondary};
  @media (min-width: ${breakpoints.tabletLandscape}px) {
    display: block;
  }
  margin-left: 32px;
  margin-right: 12px;
  font-family: Open Sans;
  font-size: 13px;
  line-height: 19px;
  text-align: right;
`
const ProfileName = styled.div`
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  margin-top: 9px;
`
const ProfileContainer = styled.div`
  display: flex;
  margin: 0px !important;
  padding: 0px !important;
  justify-content: center;
`
const ProfileType: any = styled.div`
  color: ${(props: any) => {
    if (props.isAdmin) {
      return "#00B7A2"
    }
    return props.theme.colors.primary
  }};
`
const ProfileIcon = styled(Avatar)`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin: 0;
  margin-right: 16px;
`
const iconStyles = css`
  margin-right: 8px;
  height: 18px;
  width: 18px;
  fill: #ec4d7b;
`
const UserIcon = styled(FaRegUser)`
  ${iconStyles}
`
const LogoutIcon = styled(FiLogOut)`
  ${iconStyles};
  fill: #fff;
  stroke: #ec4d7b;
`
const LogoIcon = styled(LogoSvg)`
  height: 46px;
  width: 46px;
  margin: 0;
  margin-right: 16px;
`

interface Props {
  name: string
  imgUrl: string
  logout?: Function
}

const InnerButton: React.FC<Props> = ({ name, imgUrl }) => {
  const isPhoneView =
    typeof window !== `undefined` &&
    window.innerWidth <= breakpoints.tabletLandscape
  return (
    <>
      {isPhoneView ? (
        <LogoIcon onClick={() => navigate("/")} />
      ) : (
        <>
          <ProfileInfo>
            <ProfileName>{name}</ProfileName>
          </ProfileInfo>
          {/* @ts-ignore */}
          <ProfileIcon name={name} size={36} color={theme.colors.primary} />
        </>
      )}
    </>
  )
}

const getToggle = (props: any) =>
  React.forwardRef(({ children, onClick }: any, ref) => {
    const onToggle = (e: any) => {
      e.preventDefault()
      onClick(e)
    }
    return (
      // @ts-ignore
      <Toggle ref={ref} onClick={onToggle}>
        <InnerButton name={props.name} imgUrl={props.imgUrl} />
      </Toggle>
    )
  })

const CustomMenu = React.forwardRef((props: any, ref) => {
  const { children, style, className, "aria-labelledby": labeledBy } = props
  return (
    <Menu
      // @ts-ignore
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      {children}
    </Menu>
  )
})

const ProfileButton: React.FC<Props> = props => {
  return (
    <>
      <MobileWrapper>
        <InnerButton {...props} />
      </MobileWrapper>
      <Container alignRight>
        <Dropdown.Toggle as={getToggle(props)} />
        <Dropdown.Menu as={CustomMenu}>
          <DropdownItem onClick={() => navigate("/profile")}>
            <UserIcon />
            My Profile
          </DropdownItem>
          <DropdownItem onClick={props.logout}>
            <LogoutIcon />
            Logout
          </DropdownItem>
        </Dropdown.Menu>
      </Container>
    </>
  )
}

export default ProfileButton
