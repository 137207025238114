import { UserType } from "api/types"

export const makeUserData = (
  _data: UserType | null,
  email: string,
  sub: string
) => {
  if (_data) {
    return {
      ..._data,
      email: email,
    }
  }
  return {
    id: sub,
    email,
    username: "",
    fullName: "",
    dateOfBirth: "",
    weight: 0,
    height: 0,
    bmi: null,
    quantitativeGoals: null,
    qualitativeGoals: null,
    goalMotivations: null,
    goalObstacles: null,
    badges: null,
    profilePicture: null,
    doctors: null,
    created_at: null,
    last_updated_at: null,
  }
}

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
